<template>
  <div class="company">
    <van-form>
      <van-row>
        <van-cell title="" is-link value="返回" @click="$router.back()" />
      </van-row>
      <div class="logo"><van-icon name="cross" size="60" /></div>
      <div class="title">支付失败</div>
      <div class="servie">￥{{ serviceFee }}元</div>
      <div class="servie">
        <van-button type="info" size="small">重新支付</van-button>
      </div>
      <div class="note">支付成功后，我们将在2个工作日内完成审核并通知您。</div>
      <div class="note">请前行“厨圈-商家”中查询进度。</div>
    </van-form>
    <div class="bottom"></div>
  </div>
</template>

<script>
export default {
  name: 'apply',
  data() {
    return {
      serviceFee: 0
    }
  },
  created() {},
  methods: {}
}
</script>

<style scoped>
.company .logo {
  color: #bbb;
  margin-top: 80px;
  text-align: center;
  font-size: 18px;
}
.company .logo .van-icon {
  border-radius: 60px;
  padding: 10px;
  border: 1px solid #bbb;
}
.company .title {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
}
.company .servie {
  margin: 40px;
  text-align: center;
  font-size: 16px;
}
.company .bottom {
  margin: 40px;
}
.company .note {
  margin: 20px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #ccc;
}
</style>
